

.whats-float {
    position: fixed;
    transform:translate(200px,0px);
    bottom: 1em;
    right:0;
    overflow: hidden;

    color: #FFF;
    z-index: 10;
    transition: all 500ms ease-in-out;

    &:hover {
        color: #FFFFFF;
        transform:translate(0px,0px);
    }

    &:hover .floatingBtnIcon  {
        transform:rotate(360deg);
    }
}

.floatingBtnIconDiv {
    font-size: 35px;
    color: white;
    line-height: 30px;
    padding: 1rem;
    background-color: #25d366;
}

.floatingBtnIcon {
    transform:rotate(0deg);
    transition: all 0.5s ease-in-out;
}


