/* O arquivo CSS para o componente Footer */

.footer {
    position: relative;
    text-align: center;
    background: linear-gradient(60deg, rgba(33, 92, 130, 1) 0%, rgba(1, 150, 215, 1) 100%);
    color: white;
  }
  
  .logo {
    width: 50px;
    fill: white;
    padding-right: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  
  .inner-footer {
    height: 45vh;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .waves-container {
    position: relative;
  }
  
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    min-height: 100px;
    max-height: 150px;
  }
  
  /* Animação das ondas */
  .parallax > use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
  }
  
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
  
  .footer-content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: white;
  }
  
  .footer-content p {
    font-family: 'Lato', sans-serif;
    letter-spacing: 1px;
    font-size: 14px;
    color: #333333;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .waves {
      height: 40px;
      min-height: 40px;
    }
    .footer-content {
      height: 30vh;
    }
    .footer h1 {
      font-size: 24px;
    }
  }
  