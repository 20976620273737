/*--- Nav |  01  |  Side-Slide --*/
.side-slide {
    background: #275273;
    top: 0;
    left: -85vw; 
    height: 100%;
    position: fixed;
    width: 85vw;
    padding: 3em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;

    h3 {
        color: #FFF;
        position: absolute;
        top: 2em;
        right: 2em;
        cursor: pointer;
      }

      ul {
        display: flex;
        flex-flow: column;
        gap: 1em;
      }

      li {
        text-align: center;
        border-bottom: 1px solid #F44646;
        line-height: 60px;

        &:hover {
            background-color: #F44646;
        }

        
      }

      a {
        color: white;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;

        &:hover {
            color: #fff;
          }
      }
  }

  .navSticky {
  
  span {
    color: #FFF !important;
    transition: 500ms;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.95em;

    &:hover {
      color: #2bc0ff !important;
    }
  }   
  a{
    color: #FFF !important;
    transition: 500ms;

    &:hover {
      color: #2bc0ff !important;
    }
  }


}


.StickyHeader {
  min-width: 1300px;
  max-width: 1300px;
}

@media screen and (max-width:1250px) {
  .StickyHeader {
    min-width: 100%;
  }
}

.dropdown-content a{
  color: #6E6E6E !important;
}

  .navclose {
    position: absolute;
    top: 2em;
    right: 2em;
    cursor: pointer;
  }

//   .side-slide::before {
//     content: '';
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     height: 100vh;
//     background-color: rgba(0, 0, 0, 0.5); /* Fundo preto com opacidade */
//     z-index: 10; /* Coloca o fundo atrás do menu */
//     transition: opacity 0.2s;
//     opacity: 0;
// }

.sticky {
  position: fixed;
  top: 0;
  width: 100vw;
  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;
}