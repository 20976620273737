.testimonial-slider {
    width: 100%;
    margin: 0 auto;
  }
  
  .testimonial-card {
    height: 100%;
    background: #fff;
    padding: 3em 2em;
  }
  
  .testimonial-image {
    width: 60px !important;
    height: 60px !important;
    border-radius: 100%;
  }
  
  
  .testimonial-name {
    font-weight: bold;
    margin-top: 10px;
  }



  /* Personalizando o estilo da paginação */
.splide__pagination {
    display: flex;
    justify-content: center;
    transform: translateY(4em);
  }
  
  .splide__pagination__page {
    width: 15px;
    height: 15px;
    border-radius: 0; /* Faz com que os bullets fiquem quadrados */
    background-color: transparent; /* Sem cor para os bullets inativos */
    border: 2px solid #0098DA; /* Bordas dos quadrados */
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Estiliza o bullet ativo */
  .splide__pagination__page.is-active {
    background-color: #0098DA; /* Cor do bullet ativo */
    border-color: #0098DA; /* Também alteramos a borda para combinar com a cor */
  }
  

  #splide01 > div.splide__arrows.splide__arrows--ltr > button.splide__arrow.splide__arrow--prev {

    transform: translatex(-4em);
  }

  #splide01 > div.splide__arrows.splide__arrows--ltr > button.splide__arrow.splide__arrow--next {

    transform: translatex(4em);
  }
