$textColor: #6E6E6E;
$headingColor: #32424F;
$primary: #0098DA;
$darkerBlue: #275273;

@import url('https://fonts.cdnfonts.com/css/montserrat?styles=17402,170281,17405,17395,17398,17400,17403,17391,17393,17389');

html {
    box-sizing: border-box;
    max-width: 100vw !important;
    width: 100vw !important;
    min-width: 100vw !important;
    overflow-x: hidden;
}

#root {
    max-width: 100vw !important;
    width: 100vw !important;
    min-width: 100vw !important;
}

* {
    box-sizing: border-box;
}

body {
    font-family: Montserrat, sans-serif !important;
    max-width: 100vw !important;
    width: 100vw !important;
    min-width: 100vw !important;
}

*::-webkit-scrollbar {
    background: rgb(240, 240, 240);
    width: 9px;
}

*::-webkit-scrollbar-track {
    background-color: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #0098DA;
    background-clip: content-box;
}

.headerWrapper.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideDown 0.3s ease-in-out;
}

html {
    scroll-behavior: smooth;
}

* {
    scroll-behavior: smooth;
}



@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}


h1,
h2,
h3,
h4,
h5 {
    color: $headingColor;
    font-weight: 700 !important;
    letter-spacing: -.0 1em;
    line-height: 1em;

}

h1 {
    font-size: 3.5em;
}

h2 {
    font-size: 3em;
}

h3 {
    font-size: 2em;
}

h4 {
    font-size: 1.5em;
}

@media screen and (max-width:1440px) {
    h1 {
        font-size: 3em !important;
    }

    h2 {
        font-size: 2.5em;
    }

    h3 {
        font-size: 1.5em;
    }

    h4 {
        font-size: 1.2em;
    }
}

@media screen and (max-width:1320px) {

    .content {
        max-width: 1100px !important;
    }

    h1 {
        font-size: 2.7em !important;
    }

    h2 {
        font-size: 2.2em;
    }

    h3 {
        font-size: 1.2em;
    }

    h4 {
        font-size: 1em;
    }
}

@media screen and (max-width:1100px) {
    .content {
        max-width: 950px !important;
    }
}

@media screen and (max-width:1000px) {
    .content {
        max-width: 90vw !important;
    }
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

p {
    font-family: Montserrat;
    color: $textColor;
    line-height: 1.5em;
    font-size: 1em;
}

.headerContainer {
    display: flex;
    flex-flow: column nowrap;
    width: 1250px;
    max-width: 1250px;
}

.menuButton {
    display: none;
}

@media screen and (max-width:1320px) {

    .headerContainer,
    .preHeader {
        width: 95vw !important;
        max-width: 95vw !important;

    }
}

.sideslideMenu {
    display: none;
    color: #fff;
    transition: 500ms;

    &:hover {
        color: #2bc0ff !important;
    }
}

.side-slide-backdrop {
    backdrop-filter: blur(8px);
    transition: opacity 0.5s ease;
    opacity: 0;
    pointer-events: none;
}

.side-slide-backdrop.open {
    opacity: 1;
    pointer-events: auto;
}

.side-slide {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    gap: 3em;
}



@media screen and (max-width:1024px) {
    .navHeader {
        display: none;
    }

    .cardSection {
        display: none !important;
    }

    .menuButton {
        display: flex;
    }


    .sideslideMenu {
        display: block;
    }
}

@media screen and (min-width:850px) {
    .timewpp {
        gap: 3em;
    }


    .benefitsTitle {
        min-height: 100vh;
    }
}

@media screen and (max-width:850px) {

    body {
        overflow-x: hidden;
    }

    .inner-footer img {
        width: 80%;
    }


    .flexRowMobile {
        flex-flow: row nowrap !important;
    }

    .flexRowReverseMobile {
        flex-flow: row-reverse nowrap !important;
    }

    .flexColumnMobile {
        flex-flow: column nowrap !important;
    }

    .flexColumnReverseMobile {
        flex-flow: column-reverse nowrap !important;
    }

    .firstForm {
        min-height: 100% !important;
        z-index: 2;
    }

    .cardSectionCard {
        max-width: 100% !important;
    }

    .input100 input,
    .input100 select {
        width: 100% !important;
        min-width: 100% !important;
    }

    .firstForm button {
        width: 100% !important;
    }

    .maxWidth40,
    .width55 {
        width: 100% !important;
        min-width: 100% !important;
    }

    .deliveryGrid {
        grid-template-columns: 1fr !important;
    }

    .amapaDiv {
        width: 100%;

        img {
            width: 100% !important;
        }
    }

    .sobreIMG {
        width: 100% !important;

        img {
            width: 100% !important;
        }
    }

    .sobreCTA {
        max-width: 100% !important;
        min-width: 100% !important;

        h2 {
            font-size: 2em !important;
        }
    }

    .CTASobre {
        gap: 1em !important;
    }


    .ctaNumber h1 {
        font-size: 2em !important;
    }

    .FooterBtn {
        max-width: 100% !important;
        min-width: 100% !important;
    }

    .contactformdiv {
        margin-bottom: 5em !important;
    }

    .contact {
        padding-top: 5em;
    }


    .larger1st {
        width: 100% !important;
    }

    .minor1st,
    .galery1st,
    .galery2st,
    .larger2st {
        width: 100% !important;
    }

    .minor1stImg1,
    .minor1stImg2,
    .minor2stImg1,
    .minor2stImg2 {
        width: 100% !important;
        height: 30vh !important;
    }

    .larger1st,
    .larger2st {
        width: 100% !important;
        height: 50vh !important;
    }

    .zonaAtendimento {
        width: 100% !important;
    }

    .contactcontainer {
        padding-top: 5em;
    }

    .maxWidth50 {
        max-width: 100% !important;
        width: 100% !important;
    }

    .benefitsTitle {
        height: fit-content;
    }


}

.CTASobre {
    gap: 4em;
    justify-content: stretch !important;
}


.zonaAtendimento {
    width: 50%;
}

.contactcontainer {
    padding-top: 5em;
}

.FooterBtn {
    max-width: 60%;
    gap: 2em;
}

.contact {
    padding-top: 10em;
}

.sobreCTA {
    gap: 0.8em;
    max-width: 550px;
    min-width: 550px;
    justify-content: center;

    h2 {
        font-size: 3.1em;
        color: white;
    }
}

.amapaDiv {
    width: 60%;

    img {
        width: 550px;
    }
}


@media screen and (min-width:767px) {
    .timewpp {
        gap: 3em;
    }
}


@media screen and (max-width:767px) {


    h1 {
        font-size: 2.4em !important;
    }

    .btnHeader {
        display: none;
    }

    .preHeaderSocialButton {
        display: none !important;
    }

    .timewpp {
        min-width: 100%;

    }

    .firstForm {
        width: 100% !important;
        min-width: 100% !important;
    }

}

.timewpp {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}






.preHeader {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    min-width: 100%;
    padding: 1em 0;
    color: white;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0 1em;
    height: 90px;

    nav {
        display: flex;
        flex-flow: nowrap;
        justify-content: space-between;
        gap: 2em;


        a {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.95em;
            color: #6E6E6E;
        }

        span {
            color: #6E6E6E !important;
            transition: 500ms;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.95em;

            &:hover {
                color: #2bc0ff !important;
            }
        }
    }
}

.btn {
    padding: 1.3em 2.5em;
    border: 0;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-family: Montserrat !important;
    font-size: .9em;
    text-align: center;
}

.btnBlue {
    background: $primary;
    color: #fff;
}

.btnWhite {
    background: #fff;
    color: $darkerBlue;
}

.btnBorderWhite {
    background: none;
    border: 1px solid #fff;
}

.btnBorderBlue {
    background: none;
    border: 1px solid $primary;
    color: $primary;
}

.preHeaderSocialButton {
    display: flex;
    flex-flow: row nowrap;
    gap: .5em;
}

main {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column nowrap;
    height: 110vh;
    background: url(../img/bgmain.jpg);
    background-size: cover;
    background-position: center;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }

    h1 {
        position: relative;
        color: white;
        font-size: 3em;
        font-weight: 600;
        text-align: center;
        z-index: 2;
    }
}

.main-container {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    align-items: center;
    min-height: 100vh;
    max-width: 50vw;
}

.content {
    width: 1250px;
    max-width: 1250px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardSection {
    display: flex;
    flex-flow: row nowrap;
    gap: 2em;
    justify-content: flex-end;
}

.flexColumn {
    display: flex;
    flex-flow: column nowrap;
}

.flexRow {
    display: flex;
    flex-flow: row nowrap;
}



.textHighlight {
    color: $primary;
}

.deliveryGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5em;
}

.deliveryCard {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border: 1px solid $primary;
    text-align: center;
    padding: 3em;
    gap: 1em;
    transition: 500ms;

    h4,
    p {
        color: #fff;
    }

    &:hover {
        background-color: #0098DA;
    }
}

.noBorder {
    border: none;
}

.backgroundPrimary {
    background: $primary;
}

.benefitsCards {
    gap: 1.5em;
    background-color: $primary;
    padding: 2.5em;
}

.benefitsGrid {
    gap: 2em;
}

.subheading {
    letter-spacing: .3em;
    font-size: .8em;
    text-transform: uppercase;
}

.subheadingBlue {
    letter-spacing: .3em;
    font-size: .8em;
    text-transform: uppercase;
    background: rgb(0, 152, 218);
    background: -moz-linear-gradient(90deg, rgba(0, 152, 218, 1) 0%, rgba(106, 210, 255, 1) 50%);
    background: -webkit-linear-gradient(90deg, rgba(0, 152, 218, 1) 0%, rgba(106, 210, 255, 1) 50%);
    background: linear-gradient(90deg, rgba(0, 152, 218, 1) 0%, rgba(106, 210, 255, 1) 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subheadingYellow {
    letter-spacing: .3em;
    font-size: .8em;
    text-transform: uppercase;
    background: rgb(0, 152, 218);
    background: -moz-linear-gradient(90deg, rgba(0, 152, 218, 1) 0%, rgba(106, 210, 255, 1) 50%);
    background: -webkit-linear-gradient(90deg, rgba(0, 152, 218, 1) 0%, rgba(106, 210, 255, 1) 50%);
    background: linear-gradient(90deg, rgba(0, 152, 218, 1) 0%, rgba(106, 210, 255, 1) 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

// Styles extracted from inline styles in app.js
.alignItemsFlexEnd {
    align-items: flex-end;
}

.gap2 {
    gap: 2em;
}

.cardSectionCard {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.5em;
    justify-content: center;
    max-width: 50%;
    height: 30vh;
    max-height: 225px !important;
}

.firstForm {
    width: 48%;
    min-width: 48%;
    max-width: 48%;
    background: #0098DA;
    padding: 3em;
    margin-top: -30vh;
    z-index: 2;
}

.width55 {
    width: 55%;
}

.justifyContentCenter {
    justify-content: center;
}

.alignItemsFlexStart {
    align-items: flex-start;
}

.gap1 {
    gap: 1em;
}

.paddingLeft1 {
    padding-left: 1em;
}

.gapHalf {
    gap: .5em;
}

.alignItemsCenter {
    align-items: center;
}

.iconCheck {
    color: #0098DA;
    font-size: 1.2em;
}

.maxWidth40 {
    max-width: 40%;
}

.objectFitCover {
    object-fit: cover;
}

.padding10TopBottom5Bottom {
    padding: 10em 0 5em 0;
}

.backgroundDarkerBlue {
    background: $darkerBlue;
}

.padding5TopBottom {
    padding: 5em 0;
}

.gap2_5 {
    gap: 2.5em;
}

.maxWidth50 {
    max-width: 50%;
    width: 50%;
}

.textAlignCenter {
    text-align: center;
}

.colorLightGray {
    color: #DADADA;
}

.leftBenefits {
    position: -webkit-sticky !important;
    position: sticky !important;
    align-self: flex-start;
    top: 2em;
}

#root>div.container.backgroundDarkerBlue.flexColumn>div:nth-child(2)>div.flexRow {
    width: 100%;
}

.sobreIMG {
    margin-top: -5em;
    max-width: 550px;
    width: 550px;

    img {
        width: 550px;
    }
}


/* The `Galeria` section seems to be defining styles for a gallery component or section in the SCSS
code. It includes styles for a grid layout, different sizes for gallery items, and specific styling
for images within the gallery. */
// Galeria

.galeryGrid {
    gap: 1.5em;
}

.galery1st {
    width: 68%;
    gap: 1em;
}

.galery2st {
    width: 40%;
    gap: 1em;
}

.larger1st {
    width: 60%;
    height: 100%;
}

.minor1st {
    width: 40%;
    height: 100%;
    gap: 1em
}

.minor1stImg1,
.minor1stImg2,
.minor2stImg1,
.minor2stImg2 {
    height: 100%;
    width: 100%;
}

.larger2st {
    width: 100%;
    height: 50%;
}

.minor2st {
    height: 50%;
    gap: 1em
}

.minor1stImg1 {
    background: url(../img/IMG/image1.jpg);
}

.minor1stImg2 {
    background: url(../img/IMG/image2.jpg);
}

.larger1st {
    background: url(../img/IMG/image3.jpg);
}

.minor2stImg1 {
    background: url(../img/IMG/image4.jpg);
}

.minor2stImg2 {
    background: url(../img/IMG/image5.jpg);
}

.larger2st {
    background: url(../img/IMG/image6.jpg);
}


.minor1stImg1,
.minor1stImg2,
.minor2stImg1,
.minor2stImg2,
.larger1st,
.larger2st {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.contactformdiv {
    background-color: $primary;
    padding: 5em 3em;
    gap: 2em;
    z-index: 1;
    margin-bottom: -6em;
}

input,
select,
textarea {
    background: #ffffff45;
    border: none;
    border-bottom: 3px solid white;
    padding: 1.5em;
    outline: none;
    width: 100%;
    color: #fff !important;
}

select option {
    margin: 40px;
    padding: 2em !important;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

input::placeholder,
select::placeholder,
textarea::placeholder {
    color: rgba(255, 255, 255, 0.575);
}


input:active::placeholder,
select:active::placeholder,
textarea:active::placeholder {
    color: rgb(255, 255, 255);
}

textarea {
    height: 15vh;
    resize: none;
}

#sticky {
    position: sticky;

    background: #f83d23;
    width: 100px;
    height: 100px;
    top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 6px #000;
    color: #fff;
}